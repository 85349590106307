import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const DiamondIcon = (props: IIconProps) => {
  const { width = 122, height = 82, className } = props;

  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 122 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.9591 33.7041H76.2291L92.2242 49.5224L58.5954 81.0003L29.7758 49.5224L41.9591 33.7041Z"
        stroke="#C9B098"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.7758 49.7666H92.2242"
        stroke="#C9B098"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.8624 33.7041L48.3058 48.8746L60.9077 35.2648L71.8721 48.1254L76.1084 33.7041"
        stroke="#C9B098"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.9131 51.2903L58.8114 79.2152L72.0794 49.7666"
        stroke="#C9B098"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="line"
        d="M62.2368 1V7.75568"
        stroke="#C9B098"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="line"
        d="M100.342 9.67236L95.915 13.2544"
        stroke="#C9B098"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="line"
        d="M23.1772 8.41553L27.9946 13.2545"
        stroke="#C9B098"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="line"
        d="M5.29663 20.4185L12.501 22.0838"
        stroke="#C9B098"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="line"
        d="M1 34.8413H5.73056"
        stroke="#C9B098"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="line"
        d="M121 31.2905L110.411 32.6111"
        stroke="#C9B098"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="line"
        d="M81.6365 6.24756L80.4647 9.01269"
        stroke="#C9B098"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="line"
        d="M43.705 5.99609L44.9202 9.32681"
        stroke="#C9B098"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="line"
        d="M105.636 22.5869L101.73 23.876"
        stroke="#C9B098"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default React.memo(DiamondIcon);
