import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const IntroIllustration = (props: IIconProps) => {
  const { width = 463, height = 242, className } = props;

  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 463 242"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="fillBlack"
        d="M23.509 237.486s23.269-94.214 34.475-117.863c0 0 39.13 77.594 48.437 116.723l-82.912 1.14z"
        fill="#000"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.806 201.871s36.85-113.02 86.807-153.574c49.956-40.554 120.522 87.947 141.036 124.037"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M265.408 237.866s22.889-71.231 52.046-79.399c29.062-8.167 60.214 41.314 79.019 79.399"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="fillBlack"
        d="M428.004 147.925s-17.57-8.453-30.011.095c-12.442 8.548-21.085 31.437-8.453 52.806 12.631 21.369 41.883 17.475 53.66-1.71 11.967-19.09-2.754-44.163-15.196-51.191z"
        fill="#000"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M453.077 197.407s-7.692-3.704-13.106 0-9.213 13.676-3.704 23.079c5.508 9.307 18.235 7.598 23.459-.76 5.223-8.358-1.235-19.28-6.649-22.319z"
        fill="#fff"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="fillBrown"
        d="M358.413 239.064c.353 2.106 44.238 0 44.238 0s3.882-19.881-14.236-25.495c-23.061-7.134-32.59 11.578-30.002 25.495z"
        fill="#C9B098"
        stroke="#C9B098"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M275.76 236.821L280.604 224l1.995 12.821h-6.839zM106.421 130.83c.095-.76 7.408-16.146 7.408-16.146l2.755 16.146h-10.163z"
        fill="#fff"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="fillBlack"
        d="M118.388 140.042c.855-1.044 5.034-12.346 5.034-12.346l3.039 12.441-8.073-.095z"
        fill="#000"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M228.368 127.316c0-1.71 3.419-17.76 3.419-17.76l5.319 17.76h-8.738z"
        fill="#fff"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="fillBrown"
        d="M96.924 236.631s11.017-39.224 13.581-45.398l17.761 45.398H96.924z"
        fill="#C9B098"
        stroke="#C9B098"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="fillBlack"
        d="M255.721 205.195l1.235-15.101 5.603 15.101h-6.838z"
        fill="#000"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M236.536 219.251l4.654-17.76 5.318 18.33-9.972-.57zM139.282 69.001l4.654-17.76 5.319 18.33-9.973-.57z"
        fill="#fff"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="fillBlack"
        d="M221.055 136.433l2.47-8.832 3.514 9.877-5.984-1.045zM201.871 52.95l2.469-8.832 3.514 9.782-5.983-.95z"
        fill="#000"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M227.134 86.572c-2.09 1.045-1.33 4.179.949 4.179h91.841c3.229 0 5.413-3.324 4.083-6.269 0 0 0-.094-.095-.094-2.944-5.984-13.771-.665-16.715-1.805-2.944-1.14-6.173-15.766-19.85-16.43-13.771-.57-19.279 13.2-25.738 12.251-6.458-.855-8.927-3.134-13.771-1.614-6.078 1.9-7.123 9.402-11.207 8.452-3.324-.76-6.933 0-9.497 1.33z"
        fill="#fff"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M382.702 44.118h-24.409c-1.329 0-1.899-1.614-.95-2.469.19-.19.38-.285.475-.475 2.375-1.9 5.984-1.9 7.123-1.33.38.19.76.19 1.14 0 2.089-1.329 6.648-5.413 13.866-4.083 4.654.854 5.604 3.514 5.604 5.603 0 1.52-1.33 2.755-2.849 2.755zM69.666 68.337h24.029c1.804 0 3.419-1.33 3.704-3.134.285-2.185-.285-4.939-3.989-6.174-4.749-1.614-8.453 1.33-10.352 3.134-.76.76-1.995 1.045-3.04.665-1.519-.475-3.893-.95-6.553-.285-3.324.855-4.463 2.945-4.843 4.274-.19.76.285 1.52 1.044 1.52zM1.95 90.94l4.274 4.084s2.279-3.514 7.313-7.313M23.604 96.544l3.229 2.47s1.045-1.425 3.04-3.704M290.577 14.581s2.564 1.615 3.039 2.564c0 0 .38-2.564 3.134-5.033M254.011 6.508s5.034 3.04 6.079 4.75c0 0 .76-4.75 6.268-9.308"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M330.941 166.92l3.229-12.917 4.274 12.917h-7.503z"
        fill="#fff"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="fillBlack"
        d="M351.835 191.328c.285-.759 5.508-22.983 5.508-22.983l6.079 21.749-11.587 1.234z"
        fill="#000"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M410.339 157.138l2.659 49.386M391.724 165.116s4.464 11.872 19.755 16.81M423.446 156.093s-3.989 8.073-11.397 11.872M439.306 169.199s-4.274 14.151-26.878 25.833M438.926 190.189s-5.888 3.894-17.19-.38M395.713 192.468s6.648 8.168 16.716 8.548"
        stroke="#fff"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="fillBlack"
        d="M412.998 214.122v23.744M447.664 218.871v18.995"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="fillBlack"
        d="M339.986 240.05s3.229-8.643 15.291-6.648c5.698.95 7.218 6.648 7.218 6.648h-22.509z"
        fill="#000"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="dotted"
        d="M152 235c3.442-7.953 8.655-16.701 16.817-23.991 20.948-18.556 58.025-30.75 79.662-45.993C262.936 154.81 268.836 144.736 271 134"
        stroke="#000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="12 12"
      />
      <path
        d="M59.884 147.07s3.324 47.962 4.939 84.432M49.627 168.725s5.888 4.748 12.156 6.458M80.873 181.546s-7.218 9.972-17.665 14.626M41.934 197.692s7.313 9.497 21.749 11.682M74.035 211.178s-3.704 5.984-9.782 7.408"
        stroke="#fff"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default React.memo(IntroIllustration);
