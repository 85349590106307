import React from 'react';

import { PrimaryButton } from '../components/Buttons/PrimaryButton';
import { ContentAnimate } from '../components/ContentAnimate/ContentAnimate';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ArrowIcon from '../images/ArrowLong';
import HeroSignatureIcon from '../images/Index/hero-signature';
import IntroIllustrationImage from '../images/Index/intro-illustration';
import ArrowSquirrelIcon from '../images/Projects/arrowSquirrel';
import DiamondIcon from '../images/Projects/diamond';
import revpieButton from '../images/Projects/revpie-button.svg';
import revpieText2 from '../images/Projects/revpie-text-2.svg';
import revpieText from '../images/Projects/revpie-text.svg';

import style from '../styles/pages/projects.scss';

import cn from 'classnames';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

const ProjectsPage = (props: any) => {
  const { data } = props;

  const handleJoinUs = () => {
    window.open('https://trend-capital-holdings-inc.hirehive.com', '_blank');
  };

  return (
    <Layout isDarkTheme>
      <SEO title="Projects" />
      <section className={style.hero}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-10 offset-lg-1">
              <ContentAnimate inViewAnimationClass={style.heroInView}>
                <DiamondIcon className={style.diamondIcon} />
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.heroInView}>
                <h1>We build premium products within the lead-gen ecosystem</h1>
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.heroInView}>
                <p>And invest into projects that we believe in.</p>
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.heroInView}>
                <ArrowSquirrelIcon className={style.arrowSquirrelIcon} />
              </ContentAnimate>
            </div>
          </div>
        </div>
      </section>

      <section className={style.project}>
        <div className="container">
          <div className="row align-items-center">
            <div className={cn('col-12 col-lg-8 offset-lg-2', style.headings)}>
              <div className={style.step}>01</div>
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <h2>Lead Economy</h2>
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <p>
                  A direct lead-gen network with vast connections in loan and insurance
                  verticals—powered by world class tech.
                </p>
              </ContentAnimate>
            </div>
            <div className="col-12 col-lg-6 col-xl-7 offset-lg-1 order-lg-last">
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <ParallaxProvider>
                  <Parallax y={[15, -5]}>
                    <Img
                      className={cn('lazy', style.leCodeImage)}
                      fluid={data.leCode.childImageSharp.fluid}
                      alt="code"
                      loading="eager"
                    />
                  </Parallax>
                </ParallaxProvider>
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <ParallaxProvider>
                  <Parallax y={[-15, 0]}>
                    <Img
                      className={cn('lazy', style.leDashboardImage)}
                      fluid={data.leDashboard.childImageSharp.fluid}
                      alt="dashboard"
                      loading="eager"
                    />
                  </Parallax>
                </ParallaxProvider>
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <ParallaxProvider>
                  <Parallax y={[15, -5]}>
                    <Img
                      className={cn('lazy', style.leCalendarImage)}
                      fluid={data.leCalendar.childImageSharp.fluid}
                      alt="calendar"
                      loading="eager"
                    />
                  </Parallax>
                </ParallaxProvider>
              </ContentAnimate>
            </div>
            <div
              className={cn(
                'col-12 col-md-8 offset-md-2 offset-lg-0 col-lg-5 col-xl-4',
                style.leText
              )}
            >
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <p>
                  After 10 years of perfecting the art and building relationships, Lead Economy
                  consistently delivers some of the best results in the industry.
                </p>
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <p>
                  Our technology is built and maintained in-house by talented engineers. The team
                  also consists of account managers who provide 24/7 world-class support for all of
                  our partners.
                </p>
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <a href="https://leadeconomy.com/" target="_blank" className={style.primaryButton}>
                  Leadeconomy.com <ArrowIcon />
                </a>
              </ContentAnimate>
            </div>
          </div>
        </div>
      </section>

      <section className={cn(style.project, style.revpieProject)}>
        <div className="container">
          <div className="row align-items-center">
            <div className={cn('col-12 col-lg-8 offset-lg-2', style.headings)}>
              <div className={style.step}>02</div>
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <h2>
                  RevPie <br />
                  Reject Traffic Solution
                </h2>
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <p>
                  The platform allows easy integration, gives you control over the ad creatives and
                  provides you with live and detailed statistics. With a high click-through rate and
                  high commission, you are bound to experience a substantial boost in profit.
                </p>
              </ContentAnimate>
            </div>
            <div className="col-12">
              <img
                className={cn('d-none d-lg-block', style.revpieText)}
                src={revpieText}
                alt="Revpie Text"
              />
              <img
                className={cn('d-lg-none', style.revpieText)}
                src={revpieText2}
                alt="Revpie Text"
              />
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <ParallaxProvider>
                  <Parallax y={[-10, 10]}>
                    <Img
                      className={cn('lazy', style.revpieImage)}
                      fluid={data.revpieImage.childImageSharp.fluid}
                      alt="lady"
                      loading="eager"
                    />
                  </Parallax>
                </ParallaxProvider>
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <ParallaxProvider>
                  <Parallax y={[-15, 5]}>
                    <Img
                      className={cn('lazy', style.revpiePhoneImage)}
                      fluid={data.revpiePhone.childImageSharp.fluid}
                      alt="phone"
                      loading="eager"
                    />
                  </Parallax>
                </ParallaxProvider>
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <ParallaxProvider>
                  <Parallax y={[5, -10]}>
                    <Img
                      className={cn('lazy', style.revpieVideoImage)}
                      fluid={data.revpieCar.childImageSharp.fluid}
                      alt="car"
                      loading="eager"
                    />
                  </Parallax>
                </ParallaxProvider>
              </ContentAnimate>
              <img
                className={cn(style.leIcons, style.revpieButton)}
                src={revpieButton}
                alt="LeadEconomy Icon"
              />
            </div>
            <div className="col-12 d-flex justify-content-center">
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <a href="https://revpie.com/" target="_blank" className={style.primaryButton}>
                  Revpie.com <ArrowIcon />
                </a>
              </ContentAnimate>
            </div>
          </div>
        </div>
      </section>

      <section className={cn(style.project, style.bloomProject)}>
        <div className="container">
          <div className="row">
            <div className={cn('col-12 col-lg-8 offset-lg-2', style.headings)}>
              <div className={style.step}>03</div>
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <h2>
                  Bloom <br /> CRM Solution for Creatives
                </h2>
              </ContentAnimate>
            </div>
            <div className="col-12">
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <ParallaxProvider>
                  <Parallax y={[-10, 10]}>
                    <Img
                      className={cn('lazy', style.bloomImage)}
                      fluid={data.bloomImage.childImageSharp.fluid}
                      alt="image"
                      loading="eager"
                    />
                  </Parallax>
                </ParallaxProvider>
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <ParallaxProvider>
                  <Parallax y={[10, -5]}>
                    <Img
                      className={cn('lazy', style.bloomProjectImage)}
                      fluid={data.bloomProject.childImageSharp.fluid}
                      alt="image"
                      loading="eager"
                    />
                  </Parallax>
                </ParallaxProvider>
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <ParallaxProvider>
                  <Parallax y={[5, -15]}>
                    <Img
                      className={cn('lazy', style.bloomLadyImage)}
                      fluid={data.bloomLady.childImageSharp.fluid}
                      alt="lady"
                      loading="eager"
                    />
                  </Parallax>
                </ParallaxProvider>
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <HeroSignatureIcon className={style.bloomSignatureIcon} />
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <p>
                  Bloom exists to help creatives run an efficient and profitable online business. To
                  accomplish this, we created a powerful business management and growth engine,
                  giving creatives access to modern and robust technology, all in one place.
                </p>
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <a href="https://bloom.io/" target="_blank" className={style.primaryButton}>
                  Visit Bloom <ArrowIcon />
                </a>
              </ContentAnimate>
            </div>
          </div>
        </div>
      </section>

      <section className={cn(style.project, style.WMPProject)}>
        <div className="container">
          <div className="row">
            <div className={cn('col-12 col-lg-8 offset-lg-2', style.headings)}>
              <div className={style.step}>04</div>
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <h2>
                  What’s My <br />
                  Payment?
                </h2>
                <p className={style.subHeading}>Mortgage Calculators and Home Buying Resources</p>
              </ContentAnimate>
            </div>
            <div className="col-12 col-md-6 col-lg-4 offset-lg-1">
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <Img
                  className={cn('lazy', style.wmpCalcOneImage)}
                  fluid={data.wmpCalcOne.childImageSharp.fluid}
                  alt="image"
                  loading="eager"
                />
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <p className={style.left}>Financial freedom and security starts with education.</p>
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <a
                  href="https://whatsmypayment.com/"
                  target="_blank"
                  className={cn('d-none d-md-inline-flex', style.primaryButton)}
                >
                  Visit what’s My Payment <ArrowIcon />
                </a>
              </ContentAnimate>
            </div>
            <div className="col-12 col-md-6 col-lg-4 offset-lg-2">
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <p>
                  A popular blog dedicated to helping current and future homebuyers calculate and
                  understand how a home price translates into monthly payments so they can shop for
                  houses based on their monthly budget.
                </p>
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <Img
                  className={cn('lazy', style.wmpCalcTwoImage)}
                  fluid={data.wmpCalcTwo.childImageSharp.fluid}
                  alt="image"
                  loading="eager"
                />
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <a
                  href="https://whatsmypayment.com/"
                  target="_blank"
                  className={cn('d-md-none', style.primaryButton)}
                >
                  Visit what’s My Payment <ArrowIcon />
                </a>
              </ContentAnimate>
            </div>
          </div>
        </div>
      </section>

      <section className={cn(style.project, style.listenAppProject)}>
        <div className="container">
          <div className="row">
            <div
              className={cn('col-12 col-lg-10 col-xl-8 offset-lg-1 offset-xl-2', style.headings)}
            >
              <div className={style.step}>05</div>
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <h2>
                  Listen App <br />
                  Premium podcast communities.
                </h2>
              </ContentAnimate>
            </div>
            <div className="col-12 col-md-6">
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <ParallaxProvider>
                  <Parallax y={[15, -5]}>
                    <Img
                      className={cn('lazy', style.listenAppPhonesImage)}
                      fluid={data.listenAppPhone.childImageSharp.fluid}
                      alt="phones"
                      loading="eager"
                    />
                  </Parallax>
                </ParallaxProvider>
              </ContentAnimate>
            </div>
            <div className="col-12 col-md-6">
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <p>
                  The most valuable listeners of a podcast are completely inaccessible to the
                  creators. Listen App enables podcasters to create meaningful premium VIP
                  communities for their core audience.
                </p>
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <a href="https://listenapp.co/" target="_blank" className={style.secondaryButton}>
                  Visit Listen App <ArrowIcon />
                </a>
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <Img
                  className={cn('lazy', style.listenAppLadyImage)}
                  fluid={data.listenAppLady.childImageSharp.fluid}
                  alt="lady"
                  loading="eager"
                />
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <Img
                  className={cn('lazy', style.listenAppPlayingImage)}
                  fluid={data.listenAppPlaying.childImageSharp.fluid}
                  alt="lady"
                  loading="eager"
                />
              </ContentAnimate>
            </div>
          </div>
        </div>
      </section>

      <section className={cn(style.project, style.TSRProject)}>
        <div className="container">
          <div className="row">
            <div className={cn('col-12 col-lg-8 offset-lg-2', style.headings)}>
              <div className={style.step}>06</div>
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <h2>
                  Think Save Retire <br />
                  Finance Blog
                </h2>
              </ContentAnimate>
            </div>
            <div className="col-12 col-lg-6 offset-lg-1 order-lg-last">
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <ParallaxProvider>
                  <Parallax y={[-10, 10]}>
                    <Img
                      className={cn('lazy', style.tsrHomeDImage)}
                      fluid={data.tsrHomeD.childImageSharp.fluid}
                      alt="home"
                      loading="eager"
                    />
                  </Parallax>
                </ParallaxProvider>
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <ParallaxProvider>
                  <Parallax y={[10, -10]}>
                    <Img
                      className={cn('lazy', style.tsrHomeMImage)}
                      fluid={data.tsrHomeM.childImageSharp.fluid}
                      alt="mobile"
                      loading="eager"
                    />
                  </Parallax>
                </ParallaxProvider>
              </ContentAnimate>
            </div>
            <div className="col-12 col-lg-5">
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <p>
                  When you think about financial independence and early retirement, you think about
                  freedom. Think Save Retire is a lifestyle blog that talks about finance in a
                  personal way. Our purpose is to share tools that put you in the driver’s seat on
                  the road to your life and how you want to live it.
                </p>
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <a
                  href="https://thinksaveretire.com/"
                  target="_blank"
                  className={style.primaryButton}
                >
                  Visit Think Save Retire <ArrowIcon />
                </a>
              </ContentAnimate>
            </div>
          </div>
        </div>
      </section>

      <section className={style.intro}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
              <ContentAnimate inViewAnimationClass={style.illustrationInView}>
                <IntroIllustrationImage className={style.illustrationImage} />
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <h2>Made with heart in the Pacific Northwest</h2>
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.projectInView}>
                <PrimaryButton onClick={handleJoinUs}>Join Us</PrimaryButton>
              </ContentAnimate>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
const DefaultLayoutSettingsQuery = (props: any) => {
  const renderDefaultLayoutSettings = (data: any) => <ProjectsPage data={data} {...props} />;

  return (
    <StaticQuery
      query={graphql`
        query ProjectsSection {
          leDashboard: file(relativePath: { eq: "Projects/le-dashboard.png" }) {
            childImageSharp {
              fluid(maxWidth: 820) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          leCode: file(relativePath: { eq: "Projects/le-code.png" }) {
            childImageSharp {
              fluid(maxWidth: 820) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          leCalendar: file(relativePath: { eq: "Projects/le-calendar.png" }) {
            childImageSharp {
              fluid(maxWidth: 227) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          revpieImage: file(relativePath: { eq: "Projects/revpie-image.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 654) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          revpiePhone: file(relativePath: { eq: "Projects/revpie-phone.png" }) {
            childImageSharp {
              fluid(maxWidth: 310) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          revpieCar: file(relativePath: { eq: "Projects/revpie-car.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 364) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          bloomImage: file(relativePath: { eq: "Projects/bloom-image.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 595) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          bloomProject: file(relativePath: { eq: "Projects/bloom-project.png" }) {
            childImageSharp {
              fluid(maxWidth: 820) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          bloomLady: file(relativePath: { eq: "Projects/bloom-lady.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 265) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          wmpCalcOne: file(relativePath: { eq: "Projects/wmp-calc-1.png" }) {
            childImageSharp {
              fluid(maxWidth: 412) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          wmpCalcTwo: file(relativePath: { eq: "Projects/wmp-calc-2.png" }) {
            childImageSharp {
              fluid(maxWidth: 364) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          listenAppLady: file(relativePath: { eq: "Projects/listenApp-lady.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 325) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          listenAppPhone: file(relativePath: { eq: "Projects/listenApp-phone.png" }) {
            childImageSharp {
              fluid(maxWidth: 560) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          listenAppPlaying: file(relativePath: { eq: "Projects/listenApp-playing.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          tsrHomeD: file(relativePath: { eq: "Projects/tsr-home-d.png" }) {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          tsrHomeM: file(relativePath: { eq: "Projects/tsr-home-m.png" }) {
            childImageSharp {
              fluid(maxWidth: 280) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={renderDefaultLayoutSettings}
    />
  );
};

export default DefaultLayoutSettingsQuery;
