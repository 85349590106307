import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const ArrowSignature = (props: IIconProps) => {
  const { width = 81, height = 82, className } = props;

  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 81 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1c28.983 9.987 26.96 57.426 45.834 35.787 15.098-17.31 26.062 17.2 29.656 36.62"
        stroke="#fff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.473 73.655a.985.985 0 0 0-1.363.341 1.015 1.015 0 0 0 .355 1.378l1.008-1.72zm4.872 6.524l.929-.35-.93.35zm2.052-11.626a1.017 1.017 0 0 0-.465-1.345.984.984 0 0 0-1.33.452l1.795.893zm-7.932 6.821c.638.385 1.682 1.127 2.675 2.07 1.006.955 1.875 2.035 2.276 3.085l1.858-.7c-.56-1.469-1.684-2.8-2.778-3.839-1.106-1.05-2.27-1.88-3.023-2.335l-1.008 1.72zm4.95 5.155c.069.178.112.309.137.397.03.108.021.105.018.04a.937.937 0 0 1 .1-.446.963.963 0 0 1 1.27-.439.935.935 0 0 1 .326.252c.025.032.034.05.027.037-.084-.145-.349-.79-.536-1.883-.363-2.124-.388-5.714 1.64-9.934l-1.795-.893c-2.23 4.642-2.232 8.662-1.808 11.145.207 1.211.527 2.12.78 2.557.035.062.082.137.14.21.031.04.17.22.412.332a1 1 0 0 0 1.321-.456c.133-.262.115-.514.113-.56a2.285 2.285 0 0 0-.094-.496 7.08 7.08 0 0 0-.192-.564l-1.858.701z"
        fill="#fff"
      />
    </svg>
  );
};

export default React.memo(ArrowSignature);
